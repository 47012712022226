// src/utils/api.js
import axios from 'axios';
import { getToken, logout } from './auth';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api',
  timeout: 100000,
  withCredentials: true
});

// Cached CSRF token
let csrfToken = null;
let isCsrfTokenSet = false;

// Fetch CSRF token and set it in headers
async function setCsrfToken() {
  if (!csrfToken && !isCsrfTokenSet) { // Only fetch if not already retrieved
    try {
      isCsrfTokenSet = true;
      const response = await API.get('/auth/csrf-token');
      csrfToken = response.data.csrfToken;
      API.defaults.headers.post['X-CSRF-Token'] = csrfToken;
      API.defaults.headers.put['X-CSRF-Token'] = csrfToken;
      API.defaults.headers.delete['X-CSRF-Token'] = csrfToken;
    } catch (error) {
      console.error('Failed to set CSRF token:', error);
    }
  }
}

// Request interceptor to add the token to headers
API.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    // Ensure CSRF token is set for requests that require it
    await setCsrfToken();
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token expiration and errors
API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // Token expired or unauthorized access
      if (error.response.status === 401) {
        logout();
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default API;

